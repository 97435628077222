<template>
  <v-form ref="formDados" lazy-validation v-model="formValid">
      <div class="page">
          <v-container fluid>
            <!-- <v-row class="mb-2">
              <v-col cols="12">
                  <v-card shaped elevation="1">
                    <v-card-text>
                      <v-form>
                        <v-text-field
                          label="ID Pessoa Física"
                          v-model="dados.IdPessoaFisica"
                        ></v-text-field>
                        <v-btn @click="updateVariables">
                          Atualizar
                        </v-btn>
                      </v-form>
                    </v-card-text>
                  </v-card>
              </v-col>
            </v-row> -->
              <v-row class="mb-2">
                  <v-col cols="12">
                      <v-card shaped elevation="1">
                          <h4 class="primary--text font-weight-bold pt-4 mt-0" style="text-align: center !important">
                             ÁREA DO DOCENTE
                          </h4>
                          <h5 class="accent--text font-weight-bold pb-4 mb-0" style="text-align: center"> GERENCIAMENTO DE CURRÍCULOS </h5>
                      </v-card>
                  </v-col>
              </v-row>
              <v-tabs v-model="activeTab">
                  <v-tab>Gerenciamento de curso superior</v-tab>
                  <v-tab>Gerenciamento de Pos graduação</v-tab>
                  <v-tab>Gerenciamento de outros cursos</v-tab>
              </v-tabs>
              <v-tabs-items v-model="activeTab">
                  <v-tab-item>
                      <v-row class="mb-2">
                          <v-col cols="12" md="10" sm="4">
                              <v-text-field
                                v-model="search"
                                prepend-icon="mdi-feature-search-outline"
                                label="Pesquisar"
                                clearable
                              ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="2" sm="4">
                              <v-btn
                                block
                                color="primary"
                                @click="[
                                  zerarCampos(item),
                                  (viewCriarCursoSuperior = true)
                                  ]"
                                dark
                                class="mb-2"
                              >
                                <v-icon left>mdi-plus</v-icon>
                                Novo
                              </v-btn>
                          </v-col>
                      </v-row>
                      <v-data-table
                        :headers="headersCursoSuperior"
                        :items="registros.cursos_superiores"
                        :search="search"
                        class="elevation-1"
                        :loading="loading"
                        loading-text="Carregando..."
                      >
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                icon
                                @click="[
                                  loadCurso(item),
                                  (viewEditarCursoSuperior = true)
                                  ]"
                              >
                                <v-icon color="blue">mdi-pencil</v-icon>
                              </v-btn>
                            </template>
                            <span>Editar</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                icon
                                @click="[inactiveCursoSuperior(item.id)]"
                              >
                                <v-icon color="red">mdi-delete</v-icon>
                              </v-btn>
                            </template>
                            <span>Excluir</span>
                          </v-tooltip>
                        </template>
                      </v-data-table>
                      <v-dialog
                        v-model="viewCriarCursoSuperior"
                        width="40%"
                        :fullscreen="$vuetify.breakpoint.xsOnly"
                      >
                        <v-card>
                          <v-card-title class="text-h6 grey lighten-2">
                            Adicionar curso superior
                            <v-spacer></v-spacer>
                            <v-btn icon @click="[(viewCriarCursoSuperior = false)]">
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-card-title>
                          <v-divider></v-divider>
                          <v-card-text>
                            <v-row class="mt-2">
                              <v-col cols="12" sm="12" xs="12">
                                  <v-autocomplete
                                  v-model="cursoSuperior.curso_codigo"
                                  :items="cursosSuperiorList"
                                  label="Curso"
                                  item-text="descricao"
                                  item-value="codigo"
                                  required
                                  :rules="[rules.required]"
                                ></v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="12" xs="12">
                                  <v-autocomplete
                                    v-model="cursoSuperior.instituicao_id"
                                    :items="instituicaosuperior"
                                    label="Instituição"
                                    item-text="nome"
                                    item-value="id"
                                    required
                                    :rules="[rules.required]"
                                  ></v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="6" xs="12">
                                  <v-text-field
                                    v-model="cursoSuperior.ano_conclusao"
                                    type="number"
                                    label="Ano"
                                    required
                                    :rules="[rules.required, rules.validateYear]"
                                  ></v-text-field>
                                </v-col>
                            </v-row>
                          </v-card-text>
                          <v-divider></v-divider>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="primary"
                              text
                              @click="[createCursoSuperior()]"
                            >
                              Criar
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <v-dialog
                        v-model="viewEditarCursoSuperior"
                        width="40%"
                        :fullscreen="$vuetify.breakpoint.xsOnly"
                      >
                        <v-card>
                          <v-card-title class="text-h6 grey lighten-2">
                            Editar curso superior
                            <v-spacer></v-spacer>
                            <v-btn icon @click="[(viewEditarCursoSuperior = false)]">
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-card-title>
                          <v-divider></v-divider>
                          <v-card-text>
                            <v-row class="mt-2">
                              <v-col cols="12" sm="12" xs="12">
                                  <v-autocomplete
                                  v-model="cursoSuperior.curso_codigo"
                                  :items="cursosSuperiorList"
                                  label="Curso"
                                  item-text="descricao"
                                  item-value="codigo"
                                  required
                                  :rules="[rules.required]"
                                ></v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="12" xs="12">
                                  <v-autocomplete
                                    v-model="cursoSuperior.instituicao_id"
                                    :items="instituicaosuperior"
                                    label="Instituição"
                                    item-text="nome"
                                    item-value="id"
                                    required
                                    :rules="[rules.required]"
                                  ></v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="6" xs="12">
                                  <v-text-field
                                    v-model="cursoSuperior.ano_conclusao"
                                    type="number"
                                    label="Ano"
                                    required
                                    :rules="[rules.required, rules.validateYear]"
                                  ></v-text-field>
                                </v-col>
                            </v-row>
                          </v-card-text>
                          <v-divider></v-divider>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="[updateCursoSuperior()]">
                              Atualizar
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                  </v-tab-item>
                  <v-tab-item>
                    <v-row class="mb-2">
                        <v-col cols="12" md="10" sm="4">
                            <v-text-field
                              v-model="search"
                              prepend-icon="mdi-feature-search-outline"
                              label="Pesquisar"
                              clearable
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="2" sm="4">
                            <v-btn
                              block
                              color="primary"
                              @click="[
                                zerarCampos(item),
                                (viewCriarPosGraduacao = true)
                                ]"
                              dark
                              class="mb-2"
                            >
                              <v-icon left>mdi-plus</v-icon>
                              Novo
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-data-table
                      :headers="headersPosGraduacao"
                      :items="registros.pos_graduacoes"
                      :search="search"
                      class="elevation-1"
                      :loading="loading"
                      loading-text="Carregando..."
                    >
                      <template v-slot:[`item.actions`]="{ item }">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              icon
                              @click="[
                                loadPosGraduacao(item),
                                (viewEditarPosGraduacao = true)
                                ]"
                            >
                              <v-icon color="blue">mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          <span>Editar</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              icon
                              @click="[inactivePosGraduacao(item.id)]"
                            >
                              <v-icon color="red">mdi-delete</v-icon>
                            </v-btn>
                          </template>
                          <span>Excluir</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                    <v-dialog
                      v-model="viewCriarPosGraduacao"
                      width="40%"
                      :fullscreen="$vuetify.breakpoint.xsOnly"
                    >
                      <v-card>
                        <v-card-title class="text-h6 grey lighten-2">
                          Adicionar pos graduação
                          <v-spacer></v-spacer>
                          <v-btn icon @click="[(viewCriarPosGraduacao = false)]">
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                          <v-row class="mt-2">
                            <v-col cols="12" sm="12" xs="12">
                                <v-autocomplete
                                v-model="posGraduacao.areapos_id"
                                :items="posGraduacoesList"
                                label="area"
                                item-text="nome"
                                item-value="id"
                                required
                                :rules="[rules.required]"
                              ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" xs="12">
                                <v-autocomplete
                                  v-model="posGraduacao.tipo"
                                  :items="tipoPos"
                                  label="Tipo de pos"
                                  item-text="tipo"
                                  item-value="id"
                                  required
                                  :rules="[rules.required]"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6" xs="12">
                                <v-text-field
                                  v-model="posGraduacao.ano"
                                  type="number"
                                  label="Ano"
                                  required
                                  :rules="[rules.required, rules.validateYear]"
                                ></v-text-field>
                              </v-col>
                          </v-row>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="primary"
                            text
                            @click="[createPosGraduacao()]"
                          >
                            Criar
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog
                      v-model="viewEditarPosGraduacao"
                      width="40%"
                      :fullscreen="$vuetify.breakpoint.xsOnly"
                    >
                      <v-card>
                        <v-card-title class="text-h6 grey lighten-2">
                          Editar pos graduação
                          <v-spacer></v-spacer>
                          <v-btn icon @click="[(viewEditarPosGraduacao = false)]">
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                          <v-row class="mt-2">
                          <v-col cols="12" sm="12" xs="12">
                            <v-autocomplete
                            v-model="posGraduacao.areapos_id"
                            :items="posGraduacoesList"
                            label="area"
                            item-text="nome"
                            item-value="id"
                            required
                            :rules="[rules.required]"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" xs="12">
                            <v-autocomplete
                              v-model="posGraduacao.tipo"
                              :items="tipoPos"
                              label="Tipo de pos"
                              item-text="tipo"
                              item-value="id"
                              required
                              :rules="[rules.required]"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" xs="12">
                            <v-text-field
                              v-model="posGraduacao.ano"
                              type="number"
                              label="Ano"
                              required
                              :rules="[rules.required, rules.validateYear]"
                            ></v-text-field>
                          </v-col>
                          </v-row>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="primary" text @click="[updatePosGraduacao()]">
                            Atualizar
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-tab-item>
                  <v-tab-item>
                    <v-row class="mb-2">
                        <v-col cols="12" md="10" sm="4">
                            <v-text-field
                              v-model="search"
                              prepend-icon="mdi-feature-search-outline"
                              label="Pesquisar"
                              clearable
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="2" sm="4">
                            <v-btn
                              block
                              color="primary"
                              @click="[
                                zerarCampos(item),
                                (viewCriarOutrosCursos = true)
                                ]"
                              dark
                              class="mb-2"
                            >
                              <v-icon left>mdi-plus</v-icon>
                              Novo
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-data-table
                      :headers="headersOutrosCursos"
                      :items="registros.outros_cursos"
                      :search="search"
                      class="elevation-1"
                      :loading="loading"
                      loading-text="Carregando..."
                    >
                      <template v-slot:[`item.actions`]="{ item }">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              icon
                              @click="[inactiveOutrosCursos(item.id)]"
                            >
                              <v-icon color="red">mdi-delete</v-icon>
                            </v-btn>
                          </template>
                          <span>Excluir</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                    <v-dialog
                      v-model="viewCriarOutrosCursos"
                      width="40%"
                      :fullscreen="$vuetify.breakpoint.xsOnly"
                    >
                      <v-card>
                        <v-card-title class="text-h6 grey lighten-2">
                          Adicionar outros cursos
                          <v-spacer></v-spacer>
                          <v-btn icon @click="[(viewCriarOutrosCursos = false)]">
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                          <v-row class="mt-2">
                            <v-col cols="12" sm="12" xs="12">
                                <v-autocomplete
                                v-model="outrosCursos.idinepcurso"
                                :items="outrosCursosList"
                                label="Outros Cursos"
                                item-text="Transporte"
                                item-value="id"
                                required
                                :rules="[rules.required]"
                              ></v-autocomplete>
                            </v-col>
                          </v-row>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="primary"
                            text
                            @click="[createOutrosCursos()]"
                          >
                            Criar
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-tab-item>
                  
              </v-tabs-items>
          </v-container>
          <Snackbar :snackbar="snackbar"></Snackbar>
      </div>
  </v-form>
</template>

<script>
import Snackbar from "../../components/SystemSnackbar.vue";
export default{
    components: {
        Snackbar,
    },
    data: () => ({
        activeTab: 0,
        snackbar: {
            show: false,
            timeout: 6000,
            align: "right",
        },
        cursosSuperiorList: [],
        posGraduacoesList: [],
        outrosCursosList: [],

        instituicaosuperior: [],
        registros: [],
        dados: {
            IdPessoaFisica: null,
            cpfUsuario: null,
        },
        search: '',
        cursoSuperior: {
            id: null,
            pessoafisica_id: null,
            curso_codigo: null,
            instituicao_id: null,
            ano_conclusao: null,
            usuarioins: null
        },
        headersCursoSuperior: [
            { text: 'Curso', value: 'curso.descricao' },
            { text: 'Instituição', value: 'instituicao.nome' },
            { text: 'Ano de conclusão', value: 'ano_conclusao' },
            { text: "Ações", value: "actions", width: "20%", align: "right" },
        ],
        viewCriarCursoSuperior: false,
        viewEditarCursoSuperior: false,

        posGraduacao: {
            id: null,
            pessoafisica_id: null,
            tipo: null,
            areapos_id: null,
            ano: null,
            usuarioins: null            
        },
        tipoPos:[
          {tipo: 'Especialização', id: 1},
          {tipo: 'Mestrado', id: 2},
          {tipo: 'Doutorado', id: 3},
        ],
        headersPosGraduacao: [
            { text: 'Curso', value: 'area.nome' },
            { text: 'Graduação', value: 'tipoDescricao' },
            { text: 'Ano de conclusão', value: 'ano' },
            { text: "Ações", value: "actions", width: "20%", align: "right" },
        ],
        viewCriarPosGraduacao: false,
        viewEditarPosGraduacao: false,

        outrosCursos: {
            pessoafisica_id: null,
            idinepcurso: null,
            usuarioins: null            
        },

        headersOutrosCursos: [
            { text: 'Curso', value: 'curso.Transporte' },
            { text: "Ações", value: "actions", width: "20%", align: "right" },
        ],
        viewCriarOutrosCursos: false,

        loading: false,
        formValid: false,
        rules: {
            required: (value) => !!value || 'Campo obrigatório.',
            validateYear: value => {
              const currentYear = new Date().getFullYear();
              return (value >= 1900 && value <= currentYear) || `Ano deve estar entre 1900 e ${currentYear}`;
            }
        },
    }),
    methods: {
    getUsuarioCursos(IdPessoaFisica) {
      this.loading = true;
      this.$axios
        .get(`/educacenso/meucurriculo/${IdPessoaFisica}`)
        .then((res) => {
          this.registros = res.data[0];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getCursosuperior() {
      this.loading = true;
      this.$axios
        .get("/educacenso/getallcursosuperior")
        .then((res) => {
          this.cursosSuperiorList = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getAreaPos() {
      this.loading = true;
      this.$axios
        .get("/educacenso/getallareapos")
        .then((res) => {
          this.posGraduacoesList = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getOutrosCursos() {
      this.loading = true;
      this.$axios
        .get("/educacenso/getalloutroscursos")
        .then((res) => {
          this.outrosCursosList = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getInstituicaoSuperior() {
      this.loading = true;
      this.$axios
        .get("/educacenso/getallinstituicaosuperior")
        .then((res) => {
          this.instituicaosuperior = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    createCursoSuperior() {
      this.$axios
        .post("/educacenso/pfcursos", this.cursoSuperior)
        .then((res) => {
          this.snackbar = {
            ...this.snackbar,
            show: true,
            text: res.data.message,
            color: "green",
          };
          this.viewCriarCursoSuperior = false;
          this.getUsuarioCursos(this.cursoSuperior.pessoafisica_id);
        })
        .catch((e) => {
          this.snackbar = {
            ...this.snackbar,
            show: true,
            text: e.response.data[Object.keys(e.response.data)[0]][0],
            color: "red",
          };
        });
    },
    updateCursoSuperior() {
      if (!this.$refs.formDados.validate()) return false;
      this.$axios
        .put(`/educacenso/pfcursos/${this.cursoSuperior.id}`, this.cursoSuperior)
        .then((res) => {
          this.snackbar = {
            ...this.snackbar,
            show: true,
            text: res.data.message,
            color: "green",
          };
          this.viewEditarCursoSuperior = false;
          this.getUsuarioCursos(this.cursoSuperior.pessoafisica_id);
        })
        .catch((e) => {
          this.snackbar = {
            ...this.snackbar,
            show: true,
            text: e.response.data[Object.keys(e.response.data)[0]][0],
            color: "red",
          };
        });
    },
    inactiveCursoSuperior(id) {
      let con = confirm("Deseja excluir esse curso superior?");
      if (con) {
        this.$axios
          .patch(`/educacenso/pfcursos/${id}`)
          .then((res) => {
            this.snackbar = {
              ...this.snackbar,
              show: true,
              text: res.data.message,
              color: "green",
            };
            this.getUsuarioCursos(this.cursoSuperior.pessoafisica_id);
          })
          .catch((e) => {
            this.snackbar = {
              ...this.snackbar,
              show: true,
              text: e.response.data[Object.keys(e.response.data)[0]][0],
              color: "red",
            };
          });
      }
    },

    createPosGraduacao() {
      this.$axios
        .post("/educacenso/pfpos", this.posGraduacao)
        .then((res) => {
          this.snackbar = {
            ...this.snackbar,
            show: true,
            text: res.data.message,
            color: "green",
          };
          this.viewCriarPosGraduacao = false;
          this.getUsuarioCursos(this.posGraduacao.pessoafisica_id);
        })
        .catch((e) => {
          this.snackbar = {
            ...this.snackbar,
            show: true,
            text: e.response.data[Object.keys(e.response.data)[0]][0],
            color: "red",
          };
        });
    },
    updatePosGraduacao() {
      if (!this.$refs.formDados.validate()) return false;
      this.$axios
        .put(`/educacenso/pfpos/${this.posGraduacao.id}`, this.posGraduacao)
        .then((res) => {
          this.snackbar = {
            ...this.snackbar,
            show: true,
            text: res.data.message,
            color: "green",
          };
          this.viewEditarPosGraduacao = false;
          this.getUsuarioCursos(this.posGraduacao.pessoafisica_id);
        })
        .catch((e) => {
          this.snackbar = {
            ...this.snackbar,
            show: true,
            text: e.response.data[Object.keys(e.response.data)[0]][0],
            color: "red",
          };
        });
    },
    inactivePosGraduacao(id) {
      let con = confirm("Deseja excluir esse curso superior?");
      if (con) {
        this.$axios
          .patch(`/educacenso/pfpos/${id}`)
          .then((res) => {
            this.snackbar = {
              ...this.snackbar,
              show: true,
              text: res.data.message,
              color: "green",
            };
            this.getUsuarioCursos(this.posGraduacao.pessoafisica_id);
          })
          .catch((e) => {
            this.snackbar = {
              ...this.snackbar,
              show: true,
              text: e.response.data[Object.keys(e.response.data)[0]][0],
              color: "red",
            };
          });
      }
    },

    createOutrosCursos() {
      this.$axios
        .post("/educacenso/pfoutroscursos", this.outrosCursos)
        .then((res) => {
          this.snackbar = {
            ...this.snackbar,
            show: true,
            text: res.data.message,
            color: "green",
          };
          this.viewCriarOutrosCursos = false;
          this.getUsuarioCursos(this.outrosCursos.pessoafisica_id);
        })
        .catch((e) => {
          this.snackbar = {
            ...this.snackbar,
            show: true,
            text: e.response.data[Object.keys(e.response.data)[0]][0],
            color: "red",
          };
        });
    },
    inactiveOutrosCursos(id) {
      let con = confirm("Deseja excluir esse curso superior?");
      if (con) {
        this.$axios
          .patch(`/educacenso/pfoutroscursos/${id}`)
          .then((res) => {
            this.snackbar = {
              ...this.snackbar,
              show: true,
              text: res.data.message,
              color: "green",
            };
            this.getUsuarioCursos(this.outrosCursos.pessoafisica_id);
          })
          .catch((e) => {
            this.snackbar = {
              ...this.snackbar,
              show: true,
              text: e.response.data[Object.keys(e.response.data)[0]][0],
              color: "red",
            };
          });
      }
    },

    getDados(){
      this.axios.get('usuario').then(res => {
        this.dados.IdPessoaFisica = res.data.IdPessoaFisica
        this.dados.cpfUsuario = res.data.cpfUsuario
        this.cursoSuperior.pessoafisica_id = res.data.IdPessoaFisica
        this.cursoSuperior.usuarioins = res.data.CPF
        this.posGraduacao.pessoafisica_id = res.data.IdPessoaFisica
        this.posGraduacao.usuarioins = res.data.CPF
        this.outrosCursos.pessoafisica_id = res.data.IdPessoaFisica
        this.outrosCursos.usuarioins = res.data.CPF
        this.getUsuarioCursos(res.data.IdPessoaFisica);
      })
    },
    zerarCampos() {
      this.cursoSuperior.curso_codigo   = null;
      this.cursoSuperior.instituicao_id = null;
      this.cursoSuperior.ano_conclusao  = null;
      this.posGraduacao.areapos_id      = null;
      this.posGraduacao.tipo            = null;
      this.posGraduacao.ano             = null;
      this.outrosCursos.idinepcurso     = null;
    },
    loadCurso(item) {
      this.cursoSuperior.id              = item.id;
      this.cursoSuperior.curso_codigo    = item.curso.codigo;
      this.cursoSuperior.instituicao_id  = item.instituicao.id;
      this.cursoSuperior.ano_conclusao   = item.ano_conclusao;
    },
    loadPosGraduacao(item) {
      this.posGraduacao.id            = item.id;
      this.posGraduacao.areapos_id    = item.areapos_id;
      this.posGraduacao.tipo          = item.tipo;
      this.posGraduacao.ano           = item.ano;
    },
    // updateVariables() {
    //   this.outrosCursos.pessoafisica_id = this.dados.IdPessoaFisica;

    //   this.posGraduacao.pessoafisica_id = this.dados.IdPessoaFisica;

    //   this.cursoSuperior.pessoafisica_id = this.dados.IdPessoaFisica;

    //   this.getUsuarioCursos(this.dados.IdPessoaFisica);
    // },
    },
    mounted() {
        this.getDados();
        this.getCursosuperior();
        this.getOutrosCursos();
        this.getAreaPos();
        this.getInstituicaoSuperior();
    }
}
</script>
